import React from "react";
import data from '.././dataModel';
import DoughnutChart from './DoughnutChart';
import StackedBars from "./StackedBars";
import HalfCircleChart from "./HalfCircleChart";
import CircleChart from './CircleChart';
import LinearChart from "./LinearChart";
import StackedArea from "./StackedArea";
import { getYearGraphData, getCustomKey, sortById, getClubByUrl } from "../Utils";
import LinearChartSup from "./LinearChartSup";
import TableChart from "./TableChart";
import LinearChartToggle from "./LinearChartToggle";
import Key from "./Key";

import { useSwiperStore } from "../store.js";


import { Swiper, SwiperSlide} from 'swiper/react';
class GraphView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedClubs: props.selectedClubs || [],
			selectedClubsLength: props.selectedClubsLength,
			currentGraph: props.currentGraph || '',
			currentPeriod: "2024",
			previousPeriod: "2023",
			colourToUseInHeader: "initial",
			showPreviousYearGraphData: null,
			isMarketShareView: props.isMarketShareView || false,
			activeSliderPanel: {},
			swiperInstance: useSwiperStore.getState().swiperInstance,
			isMobile: window.innerWidth <= 768,
		};

		if (!this.isOverview()) {
			this.state.selectedClubs = props.selectedClubs;
		}
	}

	componentDidUpdate(prevProps) {
		const { selectedClubs, selectedClubsLength, currentGraph } = this.props;
		this.state.graphName = prevProps.graphName;
		this.state.selectedClubs = prevProps.selectedClubs;

    	if (prevProps.selectedClubs !== selectedClubs) {
		  this.setState({
			selectedClubs,
			selectedClubsLength: selectedClubs.length,
			activeSliderPanel: selectedClubs[selectedClubs.length - 1],
		  });
		}
		
		if (prevProps.currentGraph !== currentGraph) {
		  this.setState({ currentGraph });
		}

		this.updateHistoricalClubButtonMobile();
	}

	updateHistoricalClubButtonMobile(){
		const isClubTable = this.state.currentGraph[0].graphType === "club-table";
    	const isOverview = this.isOverview();

		const isMobileAndClubOverview = isClubTable && !isOverview && this.state.isMobile;

		const historicalGraphMobileButtonPlaceholder = document.getElementById("historicalGraphData");
		historicalGraphMobileButtonPlaceholder.style.display = isMobileAndClubOverview ? "flex" : "none";
		historicalGraphMobileButtonPlaceholder.innerHTML = '';

		//Clone the previous historical club button into the footer.
		if(isMobileAndClubOverview && this.state.activeSliderPanel && this.state.activeSliderPanel.url){
			const defaultHistoricalClubButton = document.getElementById(this.state.activeSliderPanel.url);
			const newHistoricalClubButton = defaultHistoricalClubButton.cloneNode(true);
			historicalGraphMobileButtonPlaceholder.appendChild(newHistoricalClubButton);
		}

		//Update the styling of the footer to accomodate the additional button.
		const footerDiv = document.querySelector('.siteFooter div');
		if (footerDiv) {
			if (isMobileAndClubOverview) {
			  footerDiv.style.width = '50%';
			  historicalGraphMobileButtonPlaceholder.style.width = '50%'
			  document.querySelector('.siteFooter').style.marginLeft = '';
			} else {
			  footerDiv.style.width = '';
			  document.querySelector('.siteFooter').style.marginLeft = '1rem';
			}
		}
	}

	componentDidMount() {		
		const urlParams = new URLSearchParams(window.location.search);
		const graphParam = urlParams.get('graph');
		const selectedClubParam = urlParams.get('selected_club');
	  
		window.addEventListener('resize', this.handleResize);

		if (graphParam === "club-overview" && selectedClubParam) {
		  const observer = new MutationObserver((mutations) => {
			const selectedClub = getClubByUrl(selectedClubParam);
			
			// Ensure selectedClub and its first element exist
			if (selectedClub && selectedClub[0]) {
			  const button = document.getElementById(`fer-filterBtn-${selectedClub[0].id}`);
			  
			  if (button) {
				button.click();
				observer.disconnect();  // Stop observing once the element is found
				this.removeURLParam('selected_club');
			  }
			}
		  });
	  
		  // Start observing with subtree to cover nested elements and attribute changes
		  observer.observe(document.body, {
			childList: true,
			subtree: true,
			attributes: true
		  });
	  
		  // Timeout to ensure observer disconnects if button isn't found
		  setTimeout(() => {
			observer.disconnect();
		  }, 5000);
		}
	  }

	  componentWillUnmount() {
		// Remove the event listener to prevent memory leaks
		window.removeEventListener('resize', this.handleResize);
	  }

	  handleResize = () => {
		// Update the state when window is resized
		this.setState({
		  isMobile: window.innerWidth <= 768, // Update the mobile check
		});
	  };
	  
	  removeURLParam(param) {
		const url = new URL(window.location);
		url.searchParams.delete(param);
		window.history.replaceState(null, '', url);
	  }	

	getGraphDescription(graphName) {
		let description = "";

		data.graphs.forEach((graph) => {
			if (graph.title.indexOf(graphName) === 0) {
				description = graph.description;
			}
		})
		return description;
	}
	isOverview() {
		return this.props.isOverview;
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.selectedClubs.length > 0) {
			this.state.selectedClubs = nextProps.selectedClubs
		}
		this.state.currentGraph = nextProps.currentGraph;

	}
	getAllClubs(data) {
		let clubNamesArr = [];
		data.clubs.map(club => {
			clubNamesArr.push(club.name);
			return club;
		})
		return clubNamesArr;
	}
	getGraphType(graphData, club, index) {
		let numberFormat = typeof this.state.currentGraph[0]['formatting'] != "undefined" ? this.state.currentGraph[0]['formatting'] : "percent";
		let element = '';
		let clubId  = club.id;
		let shouldShowKey = club["shouldShowKey"];
		if(this.state.currentGraph[0].graphType === "linear" && !this.isOverview()){
			shouldShowKey = true;
		}
		let customKey = this.state.currentGraph[0].title === "Geographical Spread" ? getCustomKey() : "";

		switch (this.state.currentGraph[0].graphType) {
			case "club-table":
				element = !this.isOverview() && <TableChart graphData={club} currentMarketSharePeriod={this.state.currentPeriod} previousMarketSharePeriod={this.state.previousPeriod} />
				break;
			case "stacked-bars":
				element = <StackedBars showValues showKey={1} graphData={graphData} isPercentage={false} />
				break;
			case "doughnut-chart":
				element = <DoughnutChart showValues showKey={1} graphData={graphData} isPercentage={true} />
				break;
			case "halfcircle":
				element = <HalfCircleChart showValues showKey={1} graphData={graphData.currentPeriod} keyMouseEnterHandler={this.graphMouseEnterHandler} keyMouseLeaveHandler={this.graphMouseLeaveHandler} />
				break;
			case "linear":
				element = <LinearChart showKey={1}  data={graphData.currentPeriod} showOnlyFirstKey={true}/>
				break;
			case "linear-xaxis":
				element = <LinearChartSup showKey={1} data={graphData.currentPeriod} />
				break;
			case "linear-toggle":
				element = <LinearChartToggle showKey={1} data={graphData.currentPeriod} clubData={this.state.currentGraph[0]} />
				break;
			case "stacked-area":
				element = <StackedArea graphData={graphData} showTitle={1} showSubtitle={1} showValues={1}/>
				break;	
			default:
				element =
					<CircleChart
						showPrevYear
						showValues
						clipCurrentYear={false}
						showKey={shouldShowKey}
						previousGraphData={graphData.previousPeriod}
						currentGraphData={graphData.currentPeriod}
						keyMouseEnterHandler={this.graphMouseEnterHandler}
						keyMouseLeaveHandler={this.graphMouseLeaveHandler}
						clubId={clubId}
						index={index}
						previousYearClickHandler={this.previousYearClickHandler}
						showPreviousYearGraphData={this.props.showPreviousYearGraphData}
						isOverview={this.isOverview()}
						numberFormat={numberFormat}
						customKey={customKey}
					/>
		}
		this.state.renderedGraphs++;
		return (
			element
		)
	}
	isLinear() {
		let isLinear = false;
		if (this.state.currentGraph[0].graphType === "linear") {
			isLinear = true;
		}
		return isLinear;
	}
	getGraphData(club, currentPeriod, previousPeriod) {
		let graphData = {};
		if (Array.isArray(club) && this.isLinear() && !this.isOverview()) {
			let combinedData = [];
			let selectedClubsDataTmp = JSON.parse(JSON.stringify(club));
			club = club[0];
			graphData.currentPeriod = getYearGraphData(club.name, this.state.currentGraph[0].title, currentPeriod);

			graphData.previousPeriod = getYearGraphData(club.name, this.state.currentGraph[0].title, previousPeriod);
			let tmpGraphData = JSON.parse(JSON.stringify(graphData));

			selectedClubsDataTmp.map(clubData => {
				let data = JSON.parse(JSON.stringify(getYearGraphData(clubData.name, this.state.currentGraph[0].title, currentPeriod)));
				data.colour = clubData.colour;
				combinedData.push(data);
				return clubData;
			})
			if (combinedData.length >= 1) {
				for (let j = 0; j < combinedData.length; j++) {
					combinedData[j][1].colour = combinedData[j].colour;
					tmpGraphData.currentPeriod.push(combinedData[j][1]);
				}
			}
			return tmpGraphData;
		}
		else {
			graphData.currentPeriod = JSON.parse(JSON.stringify(getYearGraphData(club.name, this.state.currentGraph[0].title, currentPeriod)));
			graphData.previousPeriod = JSON.parse(JSON.stringify(getYearGraphData(club.name, this.state.currentGraph[0].title, previousPeriod)));
		}

		return graphData;
	}

	handleSwiperSlideChange = (swiper, clubs) => {
		this.setState({
			selectedClubs: [...this.state.selectedClubs],
			activeSliderPanel: clubs[swiper.activeIndex],
		});
	};
	
	handleClubGraphPanelChange = (clubs, index) => {

		const swiper = useSwiperStore.getState().swiperInstance;

		this.setState({
			activeSliderPanel: clubs[index],
			selectedClubs: [...this.state.selectedClubs],
		});

		if (swiper) {
			swiper.slideTo(index);
		}
	};
	
	handleRemoveClubGraphBanner = (club, index) => {
		document.getElementById(`fer-filterBtn-${club.id}`).click();
	};

	onClickSelectClub(clickedElement) {
		if (document.getElementsByClassName("OverviewView").length) {
			let idToClick = `fer-filterBtn-${clickedElement.id}`;
			document.getElementById(idToClick).click();
		}
	}
	getGraph(club, i, single=false) {
		let { currentPeriod, previousPeriod } = this.state;
		let graphData = this.getGraphData(club, currentPeriod, previousPeriod);
		let isClubTable = this.state.currentGraph[0].graphType === "club-table" ;

		return (
			
			<React.Fragment>
					<button 
						className={
							`fer-graphWrapper
							${(club.id === this.state.activeSliderPanel.id) || 
								((1 === this.state.selectedClubsLength) ) || 
								(0 === this.state.activeSliderPanel.id)  ? '' : 'hide'}`
							// ${(club.id === this.state.activeSliderPanel.id && this.state.selectedClubsLength > 1) ? '' : 'hide'}`
						}
						type={this.isOverview() ? 'button' : ''}  
						key={i} 
						id={"fer-graph-club-" + club.id}
						onClick={() => this.onClickSelectClub(club)}
						>

						{(this.isOverview() && isClubTable) && 
							<div className="clubOverview-grid-item">
								<img className={"fer-graphWrapper-logo"} src={require(".././assets/clubLogos/" + club.logoFile)} />
								<h3>{club.name}</h3>
							</div>
						}

						{this.isOverview() && !isClubTable && 
							<img className={"fer-graphWrapper-logo"} src={require(".././assets/clubLogos/" + club.logoFile)} />
						}


						{/* <ToggleYear isOverview={this.isOverview()} className="toggle-year" toggleYear={this.props.toggleYear} graphNav={this.props.graphNav} /> */}

						{this.state.showKeys &&
							<div>{currentPeriod}</div>
						}

						{ this.state.currentGraph[0].toggle && single ?
							<div><a onClick={() => this.toggle(this.state.currentGraph[0].toggle)} id="switch_policy_year">Policy Year</a><a onClick={() => this.toggle(this.state.currentGraph[0].toggle)} id="switch_financial_year">Financial Year</a></div>
						: '' }
						
						{this.getGraphType(graphData, club, i)}
					</button>
			</React.Fragment>
		)
	}
	getGrid(index) {
		const { selectedClubs, isMarketShareView } = this.state;
		let currentGraph = this.state.currentGraph;
		return (
			<div className={"fer-gridWrapper"} key={ index }>
				{isMarketShareView ? "" : selectedClubs.map((club, i) => (this.getGraph(club, i)))}
				<Key graphData={currentGraph} />
			</div>
		)

	}
	getRow(index) {
		let selectedClubs = sortById(this.state.selectedClubs.filter((club) => club.checked === true),"addedOrder");

		const selectedClubsLength = selectedClubs.length;
		let selectedCountClassName = "fer-selectedCount-" + selectedClubsLength;

		let isClubTable = this.state.currentGraph[0].graphType === "club-table" ;

		const clubTableKey = [
			{ label: 'Owned tonnage', key: 'grossTonnagePercent' },
			{ label: 'Premium', key: 'grossPremium' },
			{ label: 'Number of vessels', key: 'numberOfVessels' },
			{ label: 'Standard & Poor\'s rating (Interactive rating)', key: 'standardAndPoorRating' },
			{ label: 'Gross tonnage (Mutual owned) Feb24 (in mGT)', key: 'grossTonnageValue' },
			{ label: 'Chartered tonnage (in mGT)', key: 'charteredTonnage' }
		];
		return (

			<React.Fragment key={index}>
				{!this.isOverview() && selectedClubs ?
					<div 
						className={`fer-graphBanner-wrapper`}>

						{isClubTable &&
							<div className="table-chart-header">
								<h2>Club</h2>
							</div>
						}
						{selectedClubs.map((item, index) => (
							<div className={
								`fer-graphBanner-button-wrapper 
								${(item.id === this.state.activeSliderPanel.id) || 
									(1 === selectedClubsLength) ? 'is-active' : ''}`}>
								<button
									onClick={() => this.handleClubGraphPanelChange(selectedClubs, index)}
									className={`fer-graphBanner-button`}
									type="button"
									key={index}
									style={{
										borderColor: item.colour,
									}}
								>
									<h2 className="fer-graphBanner-button-title">{item.name}</h2>
									<img 
										className="fer-graphWrapper-logo" 
										src={require(".././assets/clubLogos/" + item.logoFile)}
										alt={`${item.name} Logo`} 
									/>
								</button>

						
								<button 
									disabled={selectedClubsLength === 1}
									type="button"
									className={`fer-graphBanner-button-close`}
									onClick={() => this.handleRemoveClubGraphBanner(item, index)}>
									<svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1.30699 9.35517L0.658325 8.693L4.33783 5L0.658325 1.307L1.30699 0.644836L4.99999 4.33784L8.69299 0.644836L9.34166 1.307L5.66216 5L9.34166 8.693L8.69299 9.35517L4.99999 5.66217L1.30699 9.35517Z" fill="#3F4B54"/>
									</svg>
								</button>
							
							</div>
						))}
                	</div>
				: ''}

				{(isClubTable && !this.isOverview()) ?
					<table className="chart-wrapper table-chart-wrapper">
						<thead>
							<tr>
								<td>Year</td>
							</tr>
							{clubTableKey.map((row) =>
								<tr>
									<td>{row.label}</td>
								</tr>
							)}
							<tr className="table-chart-header-footer displayNone">
								<td>Historical data</td>
							</tr>
						</thead>
						<tbody 
							className={`fer-rowWrapper ${selectedCountClassName}`}
							>

							<Swiper
								onSwiper={(swiper) => (useSwiperStore.setState({swiperInstance: swiper}))}
								onSlideChange={(swiper) => this.handleSwiperSlideChange(swiper, selectedClubs)}
								breakpoints={{
									992:{
										slidesPerView: 3
									}
								}} 
							>

							{/* {this.isLinear() ? this.getGraph(selectedClubs) :  */}
								
							{selectedClubs.map((club, i) => <SwiperSlide>{this.getGraph(club, i)}</SwiperSlide>)}
								
						</Swiper>
						</tbody>
					</table>
				:
					<div 
						className={`fer-rowWrapper ${selectedCountClassName}`}
						>
						<Swiper
							onSwiper={(swiper) => (useSwiperStore.setState({swiperInstance: swiper}))}
							onSlideChange={(swiper) => this.handleSwiperSlideChange(swiper, selectedClubs)}
							breakpoints={{
								992:{
									slidesPerView: 3
								}
							}} 
							>

							{/* {this.isLinear() ? this.getGraph(selectedClubs) :  */}
								
							{selectedClubs.map((club, i) => <SwiperSlide>{this.getGraph(club, i)}</SwiperSlide>)}
								
						</Swiper>
					</div>
				}
			</React.Fragment>
		)
	}
	render() {
		const isClubTable = this.state.currentGraph[0].graphType === "club-table" ;
		const isGraphPanelActive = this.state.isGraphPanelActive;
		const toDisplay = this.isOverview() ? this.getGrid() : this.getRow();
		const isDetailPage = true;

		return (
			<div className={`mainView ${isClubTable && !this.isOverview() ? 'is-table-view' : ''} ${isDetailPage ? 'is-detail-view' : ''}`}>
				{toDisplay}
				{(!this.isOverview() && this.state.selectedClubs.length > 1) &&
					<div className="swiper-pagination">
						{this.state.selectedClubs.map((club, index) => (
							<button
								disabled
								type="button"
								key={index}
							>
								<svg width="10" height="10">
									<circle cx="5" cy="5" r="5" fill={this.state.selectedClubs[index] === this.state.activeSliderPanel ? '#3F4B54' : '#EEE'}></circle>
								</svg>
							</button>
						))}
					</div>
				}
			</div>
		)
	}


}
export default GraphView;