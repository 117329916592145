import React, {createRef} from "react";
import {
	resetUnavailableColours,
	selectAllClubs,
	resetColourClasses,
	getFirstAvailableColour,
	returnUnavailableColour, getGraphCount, getGraphById
} from "../Utils";

// import ClearButton from "./ClearButton";
import { useFilterStore } from "../store.js";
class FilterPanelForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			clubs: [],
			isOverview: false,
			checkedCount: 0,
			availableColourClasses: [],
			colourToUse: "initial",
			addedCount: 1,
			isOverflow: undefined,
			isBottom: false
		}
		this.ref = React.createRef();
		this.maxCheckedAllowed = 3;
		this.state.clubs = selectAllClubs();
		this.state.clubs.unshift({ name: "Overview", checked: true, id: 0, colour: '#ee2c3c', shouldShowKey: false, addedOrder: 0 });
		// this.state.availableColourClasses = resetColourClasses();
		this.currentGraph = props.currentGraph;
		this.graphKeyMouseEnterHandler = props.graphKeyMouseEnterHandler;
		this.graphKeyMouseLeaveHandler = props.graphKeyMouseEnterHandler;
		this.state.availableColourClasses = resetColourClasses();
	}

	componentDidMount() {
		const { callback } = this.props;
		const element = this.ref.current;
		this.setOverview();

		document.querySelector('#fer-filterBtn-wrapper0 input').style.backgroundColor = '#0095C9';
		document.querySelector('#fer-filterBtn-wrapper0 label').style.borderColor = '#0095C9';
		
		const checkOverflow = () => {
		  if (element) {
			const hasOverflow = element.scrollHeight > (element.clientHeight + 25);
			this.setState({ 
				isOverflow: hasOverflow 
			});
			if (callback) callback(hasOverflow);
		  }
		};
	
		if (element && 'ResizeObserver' in window) {
		  this.resizeObserver = new ResizeObserver(checkOverflow);
		  this.resizeObserver.observe(element);
		}
	
		checkOverflow();
	}

    handleApplyFilters() {
        useFilterStore.setState({isFiltersOpen: false});

		const activeWrapper  = document.querySelector(".fer-graphBanner-button-wrapper.is-active");
		if (activeWrapper) {
			const buttonToClick = activeWrapper.querySelector(".fer-graphBanner-button");
			if (buttonToClick) {
				buttonToClick.click();
			}
		}
    }

	handleFiltersScroll = (event) => {
		const el = event.target;
		const isBottom = el.scrollHeight - el.scrollTop <= el.clientHeight + 5;
		this.setState({ isBottom });
	};

	getAllCheckedClubsLength() {

		return this.state.clubs.filter(checked => checked.checked === true).length;
	}
	getAllCheckedClubs() {
		return this.state.clubs.filter(checked => checked.checked === true);
	}
	setChecked(index, event, club) {

		let checkedCount = this.getAllCheckedClubsLength();
		let checked = event.target.checked;
		if (checkedCount > this.maxCheckedAllowed) {
			this.resetFilter();
			checkedCount = this.getAllCheckedClubsLength();
		}
		if ((checkedCount < this.maxCheckedAllowed && checked) || !checked) {
			if (!checked) {
				this.changeColorForSelected(event.target, club, false);

			}
			if ((index === 0 && club.name === "Overview" && checked)
				|| (index !== 0 && checkedCount <= 1 && !checked)) {

				this.resetFilter();
				this.setOverview();
				this.props.filterGetter(this.state);
				return;
			}
			if (index !== 0 && checked && this.state.clubs[0].checked) {
				this.state.clubs[0].checked = false;
			}
			if (checkedCount <= 1 && index === 0 && !checked) {
				document.querySelector('#fer-filterBtn-wrapper0 input').style.backgroundColor = '#0095C9';
				document.querySelector('#fer-filterBtn-wrapper0 label').style.borderColor = '#0095C9';
				
				checked = true;
				return;
			}
			this.state.clubs[index].checked = checked
			if (this.getAllCheckedClubsLength() === 1) {
				this.state.clubs[index].shouldShowKey = true;
			}
			this.addRemoveFilter(checked, index);
			this.changeColorForSelected(event.target, club, checked);
		} else if ((checkedCount >= this.maxCheckedAllowed && checked && index === 0) || (checkedCount <= this.maxCheckedAllowed && !checkedCount && index === 0)) {
			this.resetFilter();
			this.setOverview();
		}
		this.props.filterGetter(this.state);

		return;
	}
	resetFilter() {
		this.state.clubs.map((el, i) => {
			this.state.clubs[i].checked = false;
			this.state.clubs[i].shouldShowKey = false;
			this.state.clubs[i].colour = '';
			return el;
		})
		this.setState({ clubs: this.state.clubs }); // update state
	}
	setOverview(overviewActive) {
		let overviewState = true;
		if (typeof overviewActive != 'undefined') {
			overviewState = overviewActive;
		}
		//this.state.filtered = this.selectAllClubs();
		/*        this.state.clubs.forEach(club =>(
								club.checked = true
						))*/
		this.state.clubs[0].checked = true;
		this.state.isOverview = overviewState;
		this.state.checkedCount = 0;
		this.state.addedCount = 0;
		this.resetHiglight();
	}
	addRemoveFilter(isChecked, index) {
		if (isChecked) {
			document.querySelector('#fer-filterBtn-wrapper0 input').style.backgroundColor = 'transparent';
			document.querySelector('#fer-filterBtn-wrapper0 label').style.borderColor = 'transparent';

			this.state.clubs[index].checked = isChecked;
			this.state.isOverview = false;
			this.state.checkedCount = this.getAllCheckedClubsLength();
		}
	}
	clearFilterClickHandler = () => {
		this.resetFilter();
		this.setOverview();
		this.state.checkedCount = 0;
		this.props.filterGetter(this.state);
	}

	 resetHiglight() {
	 	const className = "fer-graphBanner-button";
	 	let filterButttons = document.getElementsByClassName(className);
	 	for (let i = 0; i < filterButttons.length; i++) {
	 		filterButttons[i].style.borderColor = 'transparent';
	 	}
		const className2 = "fer-filterBtn";
		let filterButttons2 = document.getElementsByClassName(className2);

		for (let i = 0; i < filterButttons2.length; i++) {
			filterButttons2[i].style.backgroundColor = 'transparent';
			filterButttons2[i].checked = false;
		}

		const className3 = "filterPanel-form-checkbox";
		let filterButttons3 = document.getElementsByClassName(className3);
		for (let i = 0; i < filterButttons3.length; i++) {
			filterButttons3[i].style.backgroundColor = 'transparent';
			filterButttons3[i].querySelector('label').style.borderColor = 'transparent';
		}

		document.querySelector('#fer-filterBtn-wrapper0 input').style.backgroundColor = '#0095C9';
		document.querySelector('#fer-filterBtn-wrapper0 label').style.borderColor = '#0095C9';
		
	 	resetUnavailableColours(this.state.availableColourClasses);
	 }
	 changeColorForSelected(target, club, changed) {
	 	let filterCheckboxRow = target.parentNode;
	 	let filterCheckbox = target;
	 	if (changed) {
	 		let colour = getFirstAvailableColour(this.state.availableColourClasses, club);
	 		club.colour = colour;
	 		filterCheckboxRow.style.backgroundColor = '#495965';
	 		filterCheckboxRow.querySelector('label').style.borderColor = colour;
	 		filterCheckbox.style.backgroundColor = colour;
	 		this.state.clubs[club.id].addedOrder = this.state.addedCount;
			this.state.addedCount++;
	 	} else {
	 		returnUnavailableColour(this.state.availableColourClasses, club);
	 		filterCheckboxRow.style.backgroundColor = 'transparent';
	 		filterCheckboxRow.querySelector('label').style.borderColor = 'transparent';
	 		filterCheckbox.style.backgroundColor = 'transparent';
	 	}
	 }
	getPrevNextGraphName(action) {
		let graphsLength = getGraphCount();
		let currGraphId = this.currentGraph[0].id;
		let idToUse = currGraphId;
		if (action === "next") {
			idToUse = idToUse + 1;
			if (idToUse > graphsLength) {
				idToUse = 0;
			}
		} else {
			idToUse = idToUse - 1;
			if (idToUse < 1) {
				idToUse = graphsLength;
			}
		}
		let graph = getGraphById(idToUse);

		return graph[0].title;
	}

	componentWillUnmount() {
		if (this.resizeObserver) {
		  this.resizeObserver.disconnect();
		}
	}

	render() {
		let clubs = this.state.clubs;
		let { checkedCount } = this.state;
		const { isOverflow, isBottom } = this.state;

		return (

			<form 
				ref={this.ref}
				onScroll={(event) =>  this.handleFiltersScroll(event)}
				className={`filterPanel-form ${isOverflow ? 'has-overflow' : ''}`} 	
				>
				{clubs.map((club, index) => (
					<React.Fragment key={index}>
						<div className={`filterPanel-form-input-wrapper ${club.id === 0 && club.checked ? "is-active" : ""}`}>
							<div
								className={`filterPanel-form-checkbox`}
								id={"fer-filterBtn-wrapper" + club.id}
								onMouseEnter={Event => (this.graphKeyMouseEnterHandler(Event))}
								onMouseLeave={Event => (this.graphKeyMouseLeaveHandler(Event))}>
								<input
									type="checkbox"
									className={"fer-filterBtn"}
									id={"fer-filterBtn-" + club.id}
									checked={club.checked}
									onChange={event => this.setState({checked: event.target.checked}, this.setChecked(index, event, club))}
									onClick={index === 0 ? this.clearFilterClickHandler : undefined}
								/>
								<label className={"fer-filter fer-filter-label club-id-" + club.id} htmlFor={"fer-filterBtn-" + club.id}>
									{club.name}
								</label>
							</div>
						</div>

						{club.id == 0 &&
							<div
								className={`filterPanel-form-input-wrapper ${club.id === 0 && club.checked ? "is-active" : ""}`}>
								<button
									type="button"
									disabled={(club.id != 0 && club.checked) && checkedCount === 0}
                                        onClick={() => this.handleApplyFilters()}
										className={"filterPanel-form-apply-filters"}>
										Apply
										<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9.5702 17.9078L4.37695 12.7145L5.37795 11.7135L9.5702 15.9105L18.627 6.85376L19.623 7.85476L9.5702 17.9078Z" fill="#E8EAED"/>
										</svg>
									</button>
								</div>
							}
					</React.Fragment>
				))}

				{isOverflow && !isBottom ? <small className="filterPanel-form-scroll-text">Scroll to see more</small> : ''}

				{/* {checkedCount === 3 ? <ClearButton onClickHandler={this.clearFilterClickHandler} /> : ""} */}

			</form>
		);
	}
}
export default FilterPanelForm;