import React from "react";

class TableChart extends React.Component {
    render() {

        const { graphData, showKey, currentMarketSharePeriod, previousMarketSharePeriod } = this.props;

        const currentMarketShare = graphData.marketShare[0];
        const previousMarketShare = graphData.marketShare[1];

        const marketShareKey = [
            { label: 'Owned Tonnage', key: 'grossTonnagePercent' },
            { label: 'Premium', key: 'grossPremium' },
            { label: 'Number of vessels', key: 'numberOfVessels' },
            { label: 'Standard & Poor\'s rating (Interactive rating)', key: 'standardAndPoorRating' },
            { label: 'Gross tonnage (Mutual owned) Feb24 (in mGT)', key: 'grossTonnageValue' },
            { label: 'Chartered tonnage (in mGT)', key: 'charteredTonnage' }
        ];

        return (
            <table className="table-chart">
                <tbody>
                <tr>
                    <td className="table-chart-column">
                        <tr>
                            <td className="table-chart-heading">{currentMarketSharePeriod}</td>
                        </tr>
                        {marketShareKey.map((item, index) => (
                            <tr key={index}>
                                <td className="table-chart-value">
                                    {currentMarketShare[item.key] ?
                                        `${currentMarketShare[item.key]}${item.key === 'grossTonnagePercent' || item.key === 'grossPremium' ? '%' : ''}`
                                        : 'N/A'}
                                </td>
                            </tr>
                        ))}
                    </td>
                    <td className="table-chart-column">
                        <tr>
                            <td className="table-chart-heading">{previousMarketSharePeriod}</td>
                        </tr>
                        {marketShareKey.map((item, index) => (
                            <tr key={index}>
                                <td className="table-chart-value">
                                    {previousMarketShare[item.key] ?
                                        `${previousMarketShare[item.key]}${item.key === 'grossTonnagePercent' || item.key === 'grossPremium' ? '%' : ''}`
                                        : 'N/A'}
                                </td>
                            </tr>
                        ))}
                    </td>
                </tr>

                <tr className="table-chart-footer displayNone">
                    <td colSpan={2}>
                        <a id={graphData.url} download href={require(`.././assets/download/Lockton_Marine_P&I_${graphData.url}.pdf`)}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 16.5625L7.90478 12.4625L8.87803 11.479L11.303 13.904V5.577H12.702V13.904L15.127 11.479L16.1 12.4625L12 16.5625ZM6.40728 20.173C5.93428 20.173 5.53178 20.0071 5.19978 19.6752C4.86794 19.3432 4.70203 18.9405 4.70203 18.467V15.803H6.10103V18.4662C6.10103 18.5432 6.13303 18.6138 6.19703 18.678C6.26119 18.742 6.33178 18.774 6.40878 18.774H17.5913C17.6683 18.774 17.7389 18.742 17.803 18.678C17.867 18.6138 17.899 18.5432 17.899 18.4662V15.803H19.298V18.467C19.298 18.9405 19.1321 19.3432 18.8003 19.6752C18.4683 20.0071 18.0658 20.173 17.5928 20.173H6.40728Z" fill="white"/>
                            </svg>
                            Download historical data
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }
}

export default TableChart;