import React from 'react';

import Header from '.././Elements/Header';
import Banner from '.././Elements/Banner';
import GraphView from '.././Elements/GraphView';
import BodyClassSetter from '../Elements/BodyClassSetter';
import FilterPanelFormWrapper from "../Elements/FilterPanelFormWrapper";
import Footer from "../Elements/Footer";
import data from '.././dataModel';
import {
    selectSingleClub,
    selectAllClubs, getGraphById, getGraphCount, getPrevNextGraphName, getGraphByUrl, getClubByUrl,
    toggleActiveInactiveClassOnFilterButtonHover, setNavElementAsActive, resetNavGraphObject,
    prepareNavGraphsObject, sortById, toggleGraphObject
} from "../Utils";

import { useClubStore } from '../store.js';
class DetailView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOverview: true,
            selectedClubs: selectAllClubs(),
            currentGraph: { name: "", index: "" },
            filterCount: 0,
            filterState: [],
            showKeys: true,
            nextGraph: null,
            prevGraph: null,
            urlParams: null,
            showPreviousYearGraphData: false,
            graphNav: null
        }

        this.data = data;
        this.state.urlParams = this.getGraphClubFromUrl();
        this.state.currentGraph = this.getGraphData();
        this.state.nextGraph = getPrevNextGraphName(this.state.currentGraph[0].id, 1);
        this.state.prevGraph = getPrevNextGraphName(this.state.currentGraph[0].id, 0);
        this.state.graphNav = setNavElementAsActive(this.state.currentGraph[0].id, prepareNavGraphsObject());
    }

    getGraphView(selectedClubs, graphName) {
        return (<GraphView selectedClubs={selectedClubs} graphName={graphName} />)
    }

    getFilterData = (filterData) => {
        this.state.filterState = filterData;
        this.state.selectedClubs = this.setShouldShowKey(filterData.clubs.filter((clubs) => (clubs.checked === true)))

        if (this.state.selectedClubs[0].name.indexOf("Overview") === 0 && this.state.selectedClubs[0].checked) {
            this.state.selectedClubs = selectAllClubs();
        }
        // this.state.selectedClubs = filterData.clubs.filter((clubs)=>(clubs.checked === true));
        this.state.isOverview = filterData.isOverview;
        if (this.state.isOverview) {
            this.state.showPreviousYearGraphData = false;
        }
        this.forceUpdate();
    }


    /**
     * set key and 2018 label always in first panel to the left
     * @param filter
     */
    setShouldShowKey(filter) {
        sortById(filter,"addedOrder").forEach(item => (
            item.shouldShowKey = false
        ))
        filter[0].shouldShowKey = true;
        return filter;
    }
    previousYearClickHandler = (clicked, index) => {
        this.state.showPreviousYearGraphData = clicked;
        // workaround to lift up previous year on all already visible graphs, without it if there are say 3 visible
        // and you click on show previous year, only first one will be updated
        // however when you have only 1 shown with selected previous year, all new will come with correctly rendered graphs
        if (index === 0) {
            let clickedElements = document.getElementsByClassName("overlayButton");
            for (var i = 1; i < clickedElements.length; i++) {
                clickedElements[i].click();
            }
        }
    }
    isOverview() {
        return this.state.isOverview;
    }
    navClickHandler = (clickedGraphId, navElements) => {
        this.state.currentGraph = getGraphById(clickedGraphId);
        this.state.graphNav = setNavElementAsActive(clickedGraphId, resetNavGraphObject(navElements));
        this.forceUpdate()
    }
    toggleYear = (clickedGraphId, navElements) => {
        this.state.currentGraph = getGraphById(clickedGraphId);
        toggleGraphObject(navElements).map(item => {
            if (item.id === clickedGraphId) {
                item.toggleActive = true;
            }
        })

        this.forceUpdate()
    }
    graphKeyMouseEnterHandler(hoveredElement) {
        toggleActiveInactiveClassOnFilterButtonHover(hoveredElement, 1);
    }
    graphKeyMouseLeaveHandler(hoveredElement) {
        toggleActiveInactiveClassOnFilterButtonHover(hoveredElement, 0);
    }
    getClassBasedByState() {
        var className = "DetailsView";
        if (this.state.isOverview) {
            className = "OverviewView";
        }
        return className;
    }
    nextPrevButtonsClickHandler = (action) => {
        const graphsCount = getGraphCount();
        const prevAction = "previous";
        let currId = this.state.currentGraph[0].id;
        let nextId = currId;
    
        do {
            if (action === prevAction) {
                nextId = (nextId - 1 + graphsCount) % graphsCount; // Wrap around to the end if below 0
            } else {
                nextId = (nextId + 1) % graphsCount; // Wrap around to the start if above max
            }
    
            const nextGraph = getGraphById(nextId);
    
            if (!nextGraph[0].hide) {
                this.state.currentGraph = nextGraph;
                this.state.currentGraph[0].isActive = true;
                break;
            }
        } while (nextId !== currId);
    
        resetNavGraphObject(this.state.graphNav);
        this.state.nextGraph = getPrevNextGraphName(this.state.currentGraph[0].id, 1);
        this.state.prevGraph = getPrevNextGraphName(this.state.currentGraph[0].id, 0); 
        this.forceUpdate();
    };
    

    getGraphClubFromUrl() {
        var urlParams = new URLSearchParams(window.location.search);
        return {
            graph: urlParams.get('graph'),
            club: urlParams.get('clubname')
        }

    }
    getGraphData() {
        var graphData = getGraphByUrl(this.state.urlParams.graph);
        if (graphData.length === 0) {
            graphData = getGraphById(6)
        }
        return graphData;
    }
    render() {
        const { selectedClubs, currentGraph, graphNav } = this.state;
        var nextGraph = this.state.nextGraph;
        var prevGraph = this.state.prevGraph;
        var graphTitle = currentGraph[0].description;
        const { isOverview } = this.state;

        var selectedColour = typeof this.state.filterState.colourToUse != "undefined" ? this.state.filterState.colourToUse : "";

        return (
            <BodyClassSetter customClass={this.getClassBasedByState()} responsive={true}>
                <Header includeNav='1' navClickHandler={this.navClickHandler} graphNav={graphNav} />
                
                <Banner title={graphTitle}/>
        
                <main>
                    <div className="mainView-wrapper">
                        <GraphView
                            selectedClubs={selectedClubs}
                            selectedClubsLength={selectedClubs.length}
                            currentGraph={currentGraph}
                            isOverview={this.state.isOverview}
                            showKeys={this.stateShowKeys}
                            keyMouseEnterHandler={this.graphKeyMouseEnterHandler}
                            keyMouseLeaveHandler={this.graphKeyMouseLeaveHandler}
                            selectedColourToUseInHeader={selectedColour}
                            previousYearClickHandler={this.previousYearClickHandler}
                            navClickHandler={this.navClickHandler}
                            toggleYear={this.toggleYear}
                            graphNav={graphNav}
                            showPreviousYearGraphData={this.state.showPreviousYearGraphData}
                        />
                    </div>
                    <FilterPanelFormWrapper
                        clubs={data.clubs}
                        filterGetter={this.getFilterData}
                        nextPrevClickHandler={this.nextPrevButtonsClickHandler}
                        currentGraph={currentGraph}
                        nextGraph={nextGraph}
                        prevGraph={prevGraph}
                        graphKeyMouseEnterHandler={this.graphKeyMouseEnterHandler}
                        graphKeyMouseLeaveHandler={this.graphKeyMouseLeaveHandler}
                    />
                </main>
                <Footer />
            </BodyClassSetter>
        )
    }

    setClub() {
        // todo: change it at some point so it doesn't do it by click
        var clubUrl = this.state.urlParams.club;
        if (clubUrl) {
            var club = getClubByUrl(clubUrl);
            var filterBtn = document.getElementById("fer-filterBtn-" + club[0].id);
            filterBtn.click();
        }
    }


}

export default DetailView;