import React, { useEffect } from 'react';
import Home from './Pages/Home';
import DetailView from "./Pages/DetailView";
// import TempNav from './Pages/TempNav';
import MarketShareView from './Pages/MarketShareView';
import { Switch, Route } from "react-router-dom";
import './App.scss';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import TagManager from 'react-gtm-module';

function App() {
    const GTM_ID = process.env.REACT_APP_GTM_ID;

    useEffect(() => {
        if (GTM_ID) {
            const tagManagerArgs = {
                gtmId: GTM_ID,
            };
            TagManager.initialize(tagManagerArgs);
        }
    }, []);

	return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/detail" component={DetailView} />
            {/* <Route exact path="/tempNav" component={TempNav} /> */}
            <Route exact path="/main" component={MarketShareView} />
        </Switch>
	);
}
export default App;