import data from "./dataModel";

export const colourAqua = "#0095C9";
export const colourGreen = "#009383";
export const colourYellow = "#FFB700";

export const selectAllClubs = () => {
    var arr = [];
    data.clubs.map(club => {
        arr.push({ 
            name: club.name, 
            checked: false, 
            id: club.id, 
            colour: '', 
            shouldShowKey: false, 
            logoFile: club.logo, 
            url: club.url,
            hide: club.hide,
            addedOrder: 0, 
            navDiameter: club.navDiameter,
            marketShare: [
                club.currentMarketShare,
                club.previousMarketShare   
            ]
        });
        return arr;
    })
    return arr;
}

export const selectSingleClub = (id) => {
    const club = data.clubs.find(club => club.id === id);
    if (club) {
        return [{
            name: club.name,
            checked: false,
            id: club.id,
            colour: '',
            shouldShowKey: false,
            logoFile: club.logo,
            addedOrder: 0,
            navDiameter: club.navDiameter
        }];
    }
    return []; // Return an empty array if no matching club is found
};

export const getClubById = (id) => {
    return data.clubs.filter(club => club.id === id);
}
export const getClubByUrl = (url) => {
    return data.clubs.filter(club => club.url === url);
}
export const getGraphByUrl = (graphUrl) => {
    var graphData = data.graphs.filter(graph => graph.url === graphUrl);
    return graphData;
}

export const getGraphByName = (graphName) => {
    var graphData = data.graphs.filter(graph => graph.title === graphName);
    return graphData;
}

export const getGraphById = (id) => {
    var graphData = data.graphs.filter(graph => graph.id === id);
    return graphData;
}

export const getAllGraphs = () => {
    var graphData = data.graphs;
    sortById(graphData,"id")
    return graphData;
}

export const getGraphCount = () => {
    return getAllGraphs().length;
}

export const getBackgroundColour = (numberOfChecked) => {
    var backgroundColour = "transparent";
    switch (numberOfChecked) {
        case 1:
            backgroundColour = colourGreen;
            break;
        case 2:
            backgroundColour = colourAqua;
            break;
        case 3:
            backgroundColour = colourYellow;
            break;
        default:

    }
    return backgroundColour;
}

export const getGraphId = (graphName) => {
    var graphId = "1";
    data.graphs.map(item => {
        if (item.title.indexOf(graphName) === 0) {
            graphId = item.id;
        }
        return item;
    })
    return graphId;
}
export const getGraphTypeName = (graphId) => {
    var graphType = "";
    data.graphs.map(item => {
        if (item.id === graphId) {
            graphType = item.graphType;
        }
        return item;
    })
    return graphType;
}
export const getGraphNameBeforeOrAfterId = (graphId, beforeAfter) => {

}
export const getYearGraphData = (clubName, graphName, year) => {
    var graphId = getGraphId(graphName);

    var graphType = getGraphTypeName(graphId);
    var annualData = [];
    data.clubs.map(club => {
        if (club.name.indexOf(clubName) === 0) {
            club.graphs.forEach(graphData => {
                if (graphData["data"].graphId === graphId) {
                    if (graphType === "linear" || graphType === "linear-xaxis" || graphType === "linear-toggle" || graphType === "stacked-area") {
                        annualData = graphData["data"]['graphPoints'];
                    } else {
                        annualData = graphData["data"][year];
                        /* annualData.push(graphData["data"][year]);
                         annualData.push(graphData["data"]["2018"]);*/
                    }
                }
            }
            );
        }
        return club;
    })
    return annualData;
}

export const resetColourClasses = () => {
    return [
        {
            index: 0,
            useByClubId: null,
            available: true,
            colour: "#0095C9"
        },
        {
            index: 1,
            useByClubId: null,
            available: true,
            colour: "#009383"
        },
        {
            index: 2,
            useByClubId: null,
            available: true,
            colour: "#FFB700"
        }
    ];
}
export const getFirstAvailableColour = (coloursArray, club) => {
/*
    debugger
*/
    var colour = 'initial';
    var avaliableColours = coloursArray.filter(items => (items.available === true));
    if (avaliableColours.length > 0) {
        var firstAvailable = coloursArray.filter(items => (items.available === true))[0];
        coloursArray[firstAvailable.index].available = false;
        colour = firstAvailable.colour;
        firstAvailable.useByClubId = club.id;
    }
    return colour;
}
export const returnUnavailableColour = (coloursArray, club) => {
    var colour = 'initial';
    var clubId = club.id;
    var unavaliableColour = coloursArray.filter(items => (items.useByClubId === clubId));
    if (unavaliableColour.length > 0) {
        unavaliableColour[0].available = true;
        unavaliableColour[0].usedByclubId = null;
    }
    return colour;
}

export const resetUnavailableColours = (coloursArray) => {
    if(typeof coloursArray != "undefined"){
        coloursArray.map(item => {
            item.available = true;
            item.useByClubId = null;
            return item;
        })
    }

}

export const getPrevNextGraphName = (currentGraphId, next) => {
    const graphsLength = getGraphCount(); // Total number of graphs
    let idToUse = currentGraphId;

    do {
        if (next) {
            idToUse = idToUse + 1;
            if (idToUse >= graphsLength) { // Wrap around to the start if it exceeds the last index
                idToUse = 0;
            }
        } else {
            idToUse = idToUse - 1;
            if (idToUse < 0) { // Wrap around to the end if it goes below zero
                idToUse = graphsLength - 1;
            }
        }
        const graph = getGraphById(idToUse);
        if (!graph[0].hide) {
            return graph[0].title; // Return the title of the first non-hidden graph
        }
    } while (true); // Loop until a valid graph is found
};


export const getMarketShareData = (clubId) => {
    var clubData = getClubById(clubId);
    var marketShareData = clubData[0]['currentMarketShare'];
    return marketShareData;
}

export const getClubUrl = (clubId) => {
    var club = getClubById(clubId);
    return club[0].url;
}
export const toggleActiveInactiveClassOnFilterButtonHover = (hoveredElement, active) => {
    var eventType = hoveredElement.type;
    var hoveredElementInstance = hoveredElement.target;
    var forLabel = hoveredElementInstance.getAttribute("for");
    var id = 0;
    const forLabelText =  "fer-filterBtn-";
    if (forLabel && forLabel.length) {
        id = forLabel.replace(forLabelText,"");
    }
    var correspondingElement = document.getElementById("fer-graph-club-" + id);
    var parentElement = document.querySelector(".fer-gridWrapper");

    if (correspondingElement && parentElement) {
        // debugger;
        if (eventType === "mouseenter") {
            correspondingElement.classList.add("active");
            parentElement.classList.add("active");
        } else {
            correspondingElement.classList.remove("active");
            parentElement.classList.remove("active");
        }
    }
}

export const toggleKeyHover = (hoveredElement, active) => {
    var eventType = hoveredElement.type;

    var parentElement = document.querySelector(".fer-gridWrapper");
    var keyElement = document.querySelector(".overview-key-wrapper");

    if (keyElement) {
        // debugger;
        if (eventType === "mouseenter") {
            parentElement.classList.add("key-is-active");
        }
        else {
            parentElement.classList.remove("key-is-active");
        }
    }
}

export const prepareNavGraphsObject = () => {
    var graphs = getAllGraphs();
    graphs.map(item => {
        item.isActive = false;
        return item;
    })
    return graphs;
}

export const setNavElementAsActive = (graphId, graphsNavObject) => {
    graphsNavObject.map(item => {
        if (item.id === graphId) {
            item.isActive = true;
            item.toggleActive = true;
        }
        return item;
    })
    return graphsNavObject;
}

export const resetNavGraphObject = (graphsNavObject) => {
    graphsNavObject.map(item => {
        item.isActive = false;
        item.toggleActive = false;
        return item;
    })
    return graphsNavObject;
}

export const toggleGraphObject = (graphsNavObject) => {
    graphsNavObject.map(item => {
        item.toggleActive = false;
        return item;
    })
    return graphsNavObject;
}

export const getFormattedValue = (value, format) => {
    var formattedValue = value;
    if (format === "decimal") {
        formattedValue = formatNumber(value)
    }
    return formattedValue;
}
const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const sortById = (filter, key) => {
    for (var i = 0; i < filter.length; i++) {
        for (var j = 0; j < filter.length; j++) {
            if (filter[i][key] < filter[j][key]) {
                var tmpSmaller = filter[i];
                var tmpGreater = filter[j];
                filter[j] = tmpSmaller;
                filter[i] = tmpGreater;

            }
        }
    }
    return filter;
}
export const getCustomKey = () => {
    // not all clubs have data for all regions, the request was if no data for region to not show it on graph as 0, key is
    // constructed from graph data, hence we will use "custom key" and provide data for the key from first club having all
    return getYearGraphData("American Club","Geographical Spread","2023")
}

export const _findIndex = (items, value) =>{
    var index = -1;
    if (!items) {
        return index;
    }
    
    for (var i = 0; i < items.length; ++i) {
        if (items[i].title === value) {
            index = i;
            break;
        }
    }
    return index;
}