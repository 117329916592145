import data from './dataModel';
import { create } from 'zustand';
import { selectAllClubs } from './Utils';

const allGraphs = data.graphs.map(graph => graph);

export const useGraphStore = create((set) => ({
	allGraphs: allGraphs,
	allGraphsNavigation: allGraphs
}));

export const useClubStore = create((set) => ({
	allClubs: selectAllClubs(),
	selectedClubs: [],
	setSelectedClubs: (clubs) => set({ selectedClubs: clubs }),
}));

useClubStore.subscribe(
	(state) => state.selectedClubs
);

export const useFilterStore = create((set) => ({
	activeSliderPanel: 0,
	setActiveSliderPanel: (state) => set(() => ({ activeSliderPanel: state })),

	isFiltersOpen: false,
	toggleFilters: () => set((state) => ({ isFiltersOpen: !state.isFiltersOpen })),
}));

useFilterStore.subscribe(
	(state) => state.activeSliderPanel
);

// Swiper

export const useSwiperStore = create((set) => ({
	swiperInstance: false,
	setSwiperInstane: () => set((state) => ({ swiperInstance: !state.swiperInstance })),
}));
